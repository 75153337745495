<template>
  <div style="height: 100vh;" class="column no-wrap justify-between">
    <q-dialog v-model="confirm" persistent>
      <q-card class="q-py-md q-px-sm">
        <q-card-section>
          Apakah Anda akan keluar dari halaman Hasil Kehadiran Kajian?
        </q-card-section>
        <q-card-section class="row justify-evenly">
          <q-btn
            dense
            outline
            color="indigo-9"
            style="width: 80px;"
            no-caps
            @click="confirm = false"
            label="Tidak"
          />
          <q-btn
            dense
            unelevated
            color="indigo-9"
            style="width: 80px;"
            no-caps
            @click="closeSurvey"
            label="Ya"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
    <div class="row items-center absolute-top-right q-mr-md">
      <div class="text-grey q-pr-xs">{{ surveyor.nama }}</div>
      |
      <q-btn
        dense
        flat
        @click="confirm = true"
        color="red"
        label="logout"
        no-caps
      />
    </div>
    <div class="col-auto q-pa-md q-pt-xl top text-center">
      <div class="text-h6">
        HASIL KAJIAN GURU
      </div>
      <div>Nama Guru Kepala: {{ surveyor.nama }}</div>
    </div>
    <div class="col-auto q-pa-md middle">
      <div v-for="(val, i) in result_kajian" :key="i">
        <div v-if="val.status_kehadiran == null">
          {{ val.nama }} : <strong>-</strong>
        </div>
        <div v-else>
          <div v-if="val.status_kehadiran == 'Berjalan'">
            {{ val.nama }} :
            <a class="text-positive">{{ val.status_kehadiran }} </a>
          </div>
          <div v-else-if="val.status_kehadiran == 'Tidak Berjalan'">
            {{ val.nama }} : <a>{{ val.status_kehadiran }} </a>
          </div>
          <div v-else>
            {{ val.nama }} :
            <a class="text-negative">{{ val.status_kehadiran }} </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-2 bottom  row items-end">
      <div class="row" style="width: 100%;">
        <q-btn
          class="col-12 q-pa-sm no-border-radius"
          label="Isi Kehadiran Kajian Pribadi"
          color="indigo-10"
          @click="$router.push('/hadir-kajian')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",

  data() {
    return {
      confirm: false,
      surveyor: {},
      result_kajian: [],
    };
  },
  mounted() {
    this.$http.get("/kode_unik", {}).then((result) => {
      if (localStorage.getItem("kode_unik") != result.data.kode_unik) {
        this.$router.push("/");
      }
    });
    this.$http
      .get("/guru_by_noinduk/" + localStorage.getItem("surveyor"), {})
      .then((result) => {
        this.surveyor = result.data;
      });

    this.$http
      .get("/hasil_kajian_guru/" + localStorage.getItem("surveyor"), {})
      .then((result) => {
        this.result_kajian = result.data;
      });
  },
  methods: {
    closeSurvey() {
      localStorage.removeItem("kode_unik");
      localStorage.removeItem("surveyor");
      this.$router.push("/");
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Montserrat";
  src: url("~@/assets/fonts/Montserrat-Medium.otf") format("truetype");
}

* {
  font-family: "Montserrat";
}
.top {
  // min-height: 100px;
  // padding: 5px 18px;
  // overflow-y: auto;
}
.middle {
  background-color: rgb(230, 230, 230);
  // padding: 10px;
  overflow: scroll;
  flex-grow: 99;
}
.bottom {
  // height: 100px;
}

.custom-area {
  width: 90%;
  height: 220px;
  border-radius: 3px;
  padding: 8px;
}

.custom-info pre {
  width: 180px;
  font-size: 12px;
}
</style>
